import React, { useEffect, useState } from 'react'
import css from '../css/gamehis.module.css'
import axios from 'axios'

const Referralhis = () => {
  const [user, setUser] = useState()

  const role = async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`,
    }
    await axios
      .get(`https://13.126.68.184/api/me`, { headers })
      .then((res) => {
        setUser(res.data)

        Allgames(res.data.referral_code)
        // window.location.reload()
      })
  }

  const [cardData, setGame] = useState([])

  const Allgames = async (id) => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`,
    }
    await axios
      .get(`https://13.126.68.184/api/referral/code/winn/${id}`, { headers })
      .then((res) => {
        setGame(res.data)
      })
  }

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const dateFormat = (e) => {
    if (e === null)
    {
      return ''
    }
    let date = new Date(e);
    let day = date.getUTCDate();
    let month = months[date.getUTCMonth()];
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes().toString().padStart(2, '0');
    
    // Determine AM/PM and format the hour
    let period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;  // Convert to 12-hour format, with 0 as 12
    
    // Format the date into the desired string format
    let formattedDate = `${day} ${month} at ${hours}:${minutes} ${period}`;

    return formattedDate
  }
  useEffect(() => {
    role()
    //eslint-disable-next-line
  }, [])

  if (cardData == undefined) {
    return null
  }

  return (
    <div>
      <div
        className="leftContainer"
        style={{ minHeight: '100vh', height: '100%' }}
      >
        {/* pagination */}

        <div className="pt-5 mb-3"></div>

        {/* game-cards */}
        {cardData &&
          cardData.map((card) => {
            return (
              <div
                className={`w-100 py-3 d-flex align-items-center ${css.list_item}`}
                key={card._id}
              >
                {/* map the cardData */}
                <div className={`${css.center_xy} ${css.list_date} mx-2`}>
                  <div>{dateFormat(card.createdAt)}</div>
                </div>
                <div className={`${css.list_divider_y}`} />
                <div className={`mx-3 d-flex ${css.list_body}`}>
                  <div className="d-flex align-items-center">
                    <picture className="mr-2">
                      <img
                        height="32px"
                        width="32px"
                        src={
                          process.env.PUBLIC_URL +
                          '/images/LandingPage_img/ludo.jpeg'
                        }
                        alt=""
                        style={{ borderRadius: '5px' }}
                      />
                    </picture>
                  </div>

                  <div className="d-flex flex-column font-8">
                    <div>
                      <b>Referral earning</b>.
                    </div>
                    <div className={`${css.games_section_headline}`}>
                      Earned by:{card.earned_from.Name}
                    </div>
                  </div>
                </div>

                <div className="right-0 d-flex align-items-end pr-3 flex-column">
                  <div className="d-flex float-right font-8">
                    <div className="text-danger">{card.losestatus}</div>
                    <div className="text-success">{card.winstatus}</div>
                    <picture className="ml-1 mb-1">
                      <img
                        height="21px"
                        width="21px"
                        src={
                          process.env.PUBLIC_URL +
                          '/images/LandingPage_img/global-rupeeIcon.png'
                        }
                        alt=""
                      />
                    </picture>
                    <span className="pl-1">{card.amount}</span>
                  </div>
                  <div
                    className="games-section-headline"
                    style={{ fontSize: '0.6em', whiteSpace: 'nowrap' }}
                  >
                    Closing Balance: {card.closing_balance}
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default Referralhis

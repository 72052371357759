import React from "react";
import { useState, useEffect, useRef } from 'react'
import axios from 'axios'

import "./LeaderBoard.css";


function LeaderBoard() {
  const [boardData, setBoardData] = useState()
  const [user, setUser] = useState('')

  const role = async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`,
    }
    await axios
      .get(`https://13.126.68.184/api/me`, { headers })
      .then((res) => {
        setUser(res.data)
  
        ShowLeaderBoard();
      })
  }
  const ShowLeaderBoard = async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`,
    }
    await axios
      .get(
        `https://13.126.68.184/api/leaderboard/weekly`,
        { headers },
      )
      .then((res) => {
        setBoardData(res.data);
      })
      .catch((err) => {
        console.log("Error LeaderBoard ", err);
      })
  }


  useEffect(() => {
    role()
    //eslint-disable-next-line
  }, [])
  return (
    <>
      <div>
        <div className="leftContainer" style={{ height: "100vh" }}>
          <section className="refer_page_main_section">
            <div className="refer_page_top_main_box_outer mb-5">
              <div className="refer_page_top_main_box">
                <h2>Leader Board</h2>
              </div>
            </div>

            <div className="refer_page_top_main_box_outer container-table">
              <table class="leaderboard-table">
                <thead>
                  <tr>
                    <th className="border-rad">RANK</th>
                    <th>NAME</th>
                    <th>WIN</th>
                    {/*<th className="border-rad1">MATCHES</th>*/}
                  </tr>
                </thead>
                <tbody>

                  {boardData &&
                  boardData.map((player, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{player.player_name}</td>
                          <td>{player.WinAmount}</td>
                        </tr>
                        
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default LeaderBoard;

import React, { Profiler } from 'react'

const Rightcontainer = () => {
  return (
    <div>
      <div className='rightContainer'>
        <div className='rcBanner flex-center '>

    {/*      <div  style={{ fontWeight: 'bolder', fontSize: '32px'  }}>
          गेम अब लाइव है, पेमेंट विथड्रॉ दो-तीन दिनों में शुरू होगा।
  </div>*/}
          <picture className='rcBanner-img-container animate__bounce infinite '>
            {/* <img
              src={
                JSON.parse(localStorage.getItem('sitSetting')) == 'undefined'
                  ? ''
                  : 'https://13.126.68.184/api/' +
                    JSON.parse(localStorage.getItem('sitSetting'))?.Logo
              }
              alt=""
            /> */}
            <img src='/images/khelohublogo.png' />
          </picture>
          <div className='rcBanner-text ' style={{ fontWeight: 'bolder' }}>
          SRBattel
          </div>
          <div className='rcBanner-footer'>
            For best experience, open&nbsp;
            <a
              href='/'
              style={{
                color: 'rgb(44, 44, 44)',
                fontWeight: 500,
                textDecoration: 'none'
              }}
            >
              SRBattel.com
            </a>
            &nbsp;on&nbsp;
            <img src={process.env.PUBLIC_URL + '/images/chrome.png'} alt='' />
            &nbsp;chrome mobile
          </div>
        </div>
      </div>
    </div>
  )
}

export default Rightcontainer
